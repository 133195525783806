export default class CandidateTableModel {
  constructor(candidate) {
    this.id = candidate.id;
    this.firstName = candidate.first_name;
    this.lastName = candidate.last_name;
    this.fullName = `${candidate.last_name} ${candidate.first_name}`;
    this.gender = candidate.gender;
    this.birthdate = candidate.date_of_birth;
    this.status = ''; // candidate.status;
    this.testingStatus = candidate.testing_status ?? '';

    if (candidate.tests_results) {
      this.testTime = candidate?.tests_results?.general_info?.time ?? '';
      this.degreePositionCompliance = '';
      this.riskFactors = candidate.tests_results.risk_factors?.factors ?? null;
      this.attentionFactor = candidate.tests_results.attantion_factor?.factors ?? null;

      this.scales = candidate.tests_results.scales?.scales?.scales ?? null;

      this.teamRoles = candidate.tests_results.team_roles?.team_roles?.team_roles ?? null;
      this.intellectualAbility = null;
      this.profInterests = candidate.tests_results.prof_interests?.prof_interests?.prof_interests ?? null;

      this.logic = candidate.tests_results.logic?.results ?? null;
    }

    this.comment = candidate.comment ?? '';
  }
}
