<template>
  <v-dialog v-model="dialogDeleteShow" max-width="600px">
    <v-card class="pa-8 pa-sm-16">
      <h2>Удалить?</h2>
      <div class="mt-6">
        Вы уверены, что хотите удалить кандидатов?
        <br>
        Действие не может быть отменено.
      </div>
      <div class="row flex-sm-row-reverse mt-6 mt-sm-12">
        <div class="col-sm-6 text-center">
          <v-btn @click="deleteCandidates()" class="px-16"
                 color="error" large :loading="loading.delete">Удалить</v-btn>
        </div>
        <div class="col-sm-6 pt-0 pa-sm-3 text-center">
          <v-btn @click="$emit('updateDialogDeleteShow', false)" class="px-16"
                 large outlined text>Отмена</v-btn>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'DialogDelete',
  props: {
    dialogDeleteShow: {
      type: Boolean,
      required: true,
    },
  },
  model: {
    prop: 'dialogDeleteShow',
    event: 'updateDialogDeleteShow',
  },
  computed: {
    ...mapGetters({
      loading: 'users/candidates/loading',
    }),
  },
  methods: {
    ...mapActions({
      deleteCandidates: 'users/candidates/deleteCandidates',
    }),
  },
};
</script>

<style scoped>

</style>
