import CandidateTableModel from '@/common/models/CandidateTableModel';
import * as XLSX from 'xlsx';
import {
  HEADERS, INTELLECTUAL_ABILITY, PROF_INTERESTS, SCALES,
} from '@/common/constants/candidateTableHeaders';
import { TESTING_STATUSES_TITLES } from '@/common/constants/testingStatuses';
import { GENDERS } from '@/common/constants/genders';
import moment from 'moment';

const NOT_DATA_TEXT = '-';

export default class CandidatesToTable {
  constructor(candidates) {
    this.candidates = candidates;
    this.toTable();
  }

  static getGender(gender) {
    if (gender === GENDERS.MALE) {
      return 'М';
    }
    if (gender === GENDERS.FEMALE) {
      return 'Ж';
    }
    return NOT_DATA_TEXT;
  }

  static getBirthdate(birthdate) {
    return birthdate ? moment(birthdate).format('YYYY') : NOT_DATA_TEXT;
  }

  static sortScales(scales) {
    if (scales) {
      const tmp = [];
      Object.values(SCALES).forEach((title) => {
        const item = scales.find((el) => (`${el.title_start}/${el.title_end}`) === title);
        if (item) {
          tmp.push(item.value);
        } else {
          tmp.push(NOT_DATA_TEXT);
        }
      });
      return tmp;
    }
    return new Array(13).fill(NOT_DATA_TEXT);
  }

  static sortProfInterests(profInterests) {
    if (profInterests) {
      const tmp = [];
      Object.values(PROF_INTERESTS).forEach((title) => {
        const item = profInterests.find((el) => el.title === title);
        if (item) {
          tmp.push(item.points);
        } else {
          tmp.push(NOT_DATA_TEXT);
        }
      });
      return tmp;
    }
    return new Array(12).fill(NOT_DATA_TEXT);
  }

  static sortLogic(logic) {
    if (logic) {
      const tmp = [];
      Object.values(INTELLECTUAL_ABILITY).forEach((title) => {
        const item = logic.find((el) => el.title === title);
        if (item) {
          tmp.push(item.value);
        } else {
          tmp.push(NOT_DATA_TEXT);
        }
      });
      return tmp;
    }
    return new Array(3).fill(NOT_DATA_TEXT);
  }

  toTable() {
    const headersRow0 = [
      HEADERS.FIO,
      HEADERS.GENDER,
      HEADERS.BIRTHDATE,
      HEADERS.STATUS,
      HEADERS.TEST_TIME,
      HEADERS.TESTING_STATUS,
      HEADERS.DEGREE_POSITION_COMPLIANCE,
      HEADERS.RISK_FACTORS,
      HEADERS.ATTENTION_FACTOR,
      HEADERS.SCALES, '', '', '', '', '', '', '', '', '', '', '', '',
      HEADERS.TEAM_ROLES,
      HEADERS.INTELLECTUAL_ABILITY, '', '',
      HEADERS.PROF_INTERESTS, '', '', '', '', '', '', '', '', '', '', '',
      HEADERS.COMMENT,
    ];
    const headersRow1 = [
      '', '', '', '', '', '', '', '', '',
      SCALES['0'],
      SCALES['1'],
      SCALES['2'],
      SCALES['3'],
      SCALES['4'],
      SCALES['5'],
      SCALES['6'],
      SCALES['7'],
      SCALES['8'],
      SCALES['9'],
      SCALES['10'],
      SCALES['11'],
      SCALES['12'],
      '',
      INTELLECTUAL_ABILITY['0'],
      INTELLECTUAL_ABILITY['1'],
      INTELLECTUAL_ABILITY['2'],
      PROF_INTERESTS['0'],
      PROF_INTERESTS['1'],
      PROF_INTERESTS['2'],
      PROF_INTERESTS['3'],
      PROF_INTERESTS['4'],
      PROF_INTERESTS['5'],
      PROF_INTERESTS['6'],
      PROF_INTERESTS['7'],
      PROF_INTERESTS['8'],
      PROF_INTERESTS['9'],
      PROF_INTERESTS['10'],
      PROF_INTERESTS['11'],
    ];

    const rows = this.candidates.map((c) => {
      const candidate = new CandidateTableModel(c);

      let riskFactors = NOT_DATA_TEXT;
      if (candidate.riskFactors) {
        riskFactors = candidate.riskFactors?.map((f) => f.title).join(';\n');
      }
      const attentionFactor = NOT_DATA_TEXT;
      if (candidate.attentionFactor) {
        riskFactors = candidate.attentionFactor?.map((f) => f.title).join(';\n');
      }

      const teamRolesText = candidate.teamRoles
        ? candidate.teamRoles.map((r) => `${r.title} - ${r.points}`).join(';\n')
        : NOT_DATA_TEXT;

      return [
        candidate.fullName || NOT_DATA_TEXT,
        CandidatesToTable.getGender(candidate.gender),
        CandidatesToTable.getBirthdate(candidate.birthdate),
        candidate.status || NOT_DATA_TEXT,
        candidate.testTime || NOT_DATA_TEXT,
        TESTING_STATUSES_TITLES[candidate.testingStatus] ?? NOT_DATA_TEXT,
        candidate.degreePositionCompliance || NOT_DATA_TEXT,
        riskFactors,
        attentionFactor,

        ...CandidatesToTable.sortScales(candidate.scales),

        teamRolesText,

        ...CandidatesToTable.sortLogic(candidate.logic),

        ...CandidatesToTable.sortProfInterests(candidate.profInterests),

        candidate.comment || NOT_DATA_TEXT,
      ];
    });

    const data = [
      headersRow0,
      headersRow1,
      ...rows,
    ];

    const ws = XLSX.utils.aoa_to_sheet(data);
    const merge = [
      { s: { r: 0, c: 9 }, e: { r: 0, c: 21 } },
      { s: { r: 0, c: 23 }, e: { r: 0, c: 25 } },
      { s: { r: 0, c: 26 }, e: { r: 0, c: 37 } },
    ];
    ws['!merges'] = merge;

    // ws['!cols'] = [
    //   { wch: Math.max(HEADERS.FIO.length, 30) },
    //   { wch: Math.max(HEADERS.GENDER.length, 20) },
    //   { wch: Math.max(HEADERS.BIRTHDATE.length, 20) },
    // ];

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Кандидаты');

    XLSX.writeFile(wb, 'Кандидаты.xlsx');

    return this.candidates;
  }
}
